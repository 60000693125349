import React from "react";
import STYLE from "../home page/HomePage.module.css";

export const HomePage = () => {
  return (
    <div className={STYLE.container}>
      <div>Home Page</div>
    </div>
  );
};
